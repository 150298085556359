import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [res, setRes] = useState({});

  const [loaded, setLoaded] = useState(false);
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer secret_MbNIoD4iCysg5p3npuEYbi3pTxnAC8Ryd4oDj5kf4Zt"
  );
  myHeaders.append("Notion-Version", "2022-06-28");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    filter: {
      and: [
        {
          property: "Publish",
          checkbox: {
            equals: true,
          },
        },
        {
          property: "Date",
          date: {
            next_year: {},
          },
        },
      ],
    },
    sorts: [
      {
        property: "Date",
        direction: "ascending",
      },
    ],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  useEffect(() => {
    setTimeout(() => {
      fetchEvents();
    }, 1);
  }, []);

  const fetchEvents = async () => {
    const response = await fetch(
      `https://api.notion.com/v1/databases/897e5a76ae524b489fdfe71f5945d1af/query`,
      requestOptions
    );
    setRes(response.text());
    console.log(res);
    setLoaded(true);
  };

  return (
    <div className="App">
      <h1>Hi Events</h1>

      {loaded &&
        res.results.map((elm) => {
          return (
            <div>
              <p>{elm.properties.Name.title[0].plain_text}</p>
              <p>{elm.properties.Date.date.start}</p>
            </div>
          );
        })}
    </div>
  );
}

export default App;
